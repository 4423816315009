<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Paramètres ></span>
        <router-link :to="'/utilisateurs'">
          Utilisateurs >
        </router-link>
        <span>Modification d'utilisateur</span>
      </div>
    </div>
    <h2 class="text-center">MODIFIER UTILISATEUR</h2>
    <div class="inner_container">
      <div class="row">
        <Notif :notif="notif" />
      </div>
      <div class="col-sm-5 mx-auto">
        <form>
          <div class="form-row row">
            <div class="form-group col-md-6">
              <label for="">Nom & prénom</label>
              <input type="text"
                     name=""
                     id=""
                     v-model="form.nom"
                     class="form-control" />
              <small id="helpId"
                     class="text-muted">Help text</small>
            </div>
            <div class="form-group col-md-6">
              <label for="">Email</label>
              <input type="email"
                     name=""
                     id=""
                     v-model="form.email"
                     class="form-control" />
              <small id="helpId"
                     class="text-muted">Help text</small>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-6">
              <label for="">Fonction</label>
              <select class="form-control"
                      name=""
                      id=""
                      v-model="form.type_utilisateur">
                <option value="DT">DT</option>
                <option value="DAF">DAF</option>
                <option value="DSI">DSI</option>
                <option value="DCG">DCG</option>
                <option value="DG">DG</option>
                <option value="DMC">DMC</option>
                <option value="DGA">DGA</option>
                <option value="DAFP">DAFP</option>
                <option value="COMPTABILITE">COMPTABILITE</option>
                <option value="AUDIT">AUDIT</option>
                <option value="ADMIN">ADMIN</option>
                <option value="DAF">ADMIN_DAF</option>
                <option value="DT">ADMIN_DT</option>
                <option value="DCG">ADMIN_DCG</option>
                <option value="AUTRE">AUTRE</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="">Entreprise</label>
              <select class="form-control"
                      name=""
                      id=""
                      v-model="form.groupe_utilisateur">
                <option value="FER">FER</option>
                <option value="AGEROUTE">AGEROUTE</option>
                <option value="DGIR">DGIR</option>
              </select>
            </div>
          </div>
          <div class="form-row row">
            <div class="form-group col-md-6"
                 v-if="form.groupe_utilisateur=='FER'">
              <label for="">Profil métier</label>
              <select class="form-control"
                      name=""
                      id=""
                      v-model="form.profil_metier">
                <option value="niveau_1">Chargé d'opération</option>
                <option value="niveau_2">Chef de service</option>
                <option value="niveau_3">Chef de département</option>
                <option value="niveau_4">Directeur</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="">Status</label>
              <select class="form-control"
                      name=""
                      id=""
                      v-model="form.etat">
                <option value="active">Activé</option>
                <option value="desactive">Désactivé</option>
              </select>
            </div>
          </div>
          
          
          
          <div class="button_form">
            <button type="button"
                    class="btn btn-primary mr-4"
                    @click="submit">
              <!-- <i class="flaticon flaticon-floppy"></i> -->
              Modifier
            </button>
            <button type="reset"
                    class="btn btn-primary">Annuler</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
input[type="email"],
input[type="text"],
form label,
select.form-control,
.btn-primary{
  font-size: 0.6em;
}
select.form-control option{
  font-size: 0.8em;
}
input[type="email"],
input[type="text"]{
  height: fit-content;
}
</style>
<script>
import form from "vuejs-form"
import { mapActions, mapGetters } from "vuex"
import Notif from "@/components/shared/Toast"

export default {
  name: "AddUser",
  components: {
    Notif
  },
  data: () => ({
    form: form({
      nom: "",
      email: "",
      type_utilisateur: "",
      profil_metier: "",
      groupe_utilisateur: "",
      etat:"active"
    })
      .rules({
        nom: "required",
        email: "required",
        type_utilsateur: "required",
        profil_metier: "required",
        groupe_utilisateur: "required"
      })
      .messages({
        nom: "Le champ est requis",
        email: "Le champ est requis",
        type_utilsateur: "Le champ est requis",
        groupe_utilisateur: "Le champ est requis"
      }),
    notif: { message: "", type: "", activated: "" }
  }),
  created(){
    this.getUser(this.$route.params.id)
  },
  watch: {
    usersDetails() {
      if (this.usersDetails) {
        console.log(this.usersDetails)
        this.form.nom= this.usersDetails.nom
        this.form.email= this.usersDetails.email
        this.form.type_utilisateur= this.usersDetails.type
        this.form.profil_metier= this.usersDetails.profil_metier
        this.form.groupe_utilisateur= this.usersDetails.groupe
        this.form.etat= this.usersDetails.etat
      }
    },
    userMsg() {
      if (this.userMsg) {
        this.notif.message = this.userMsg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$router.push({ name: "Utilisateur" })
            location.reload()
          }.bind(this),
          3000
        )
      }
    },
    errors() {
      if (this.errors) {
        this.notif.message = this.errors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["usersDetails","userMsg", "errors"])
  },
  methods: {
    ...mapActions(["getUser","updateUser"]),
    submit() {
      this.updateUser({id:this.$route.params.id, data:this.form.data})
    },
  }

}
</script>
